import React, { useState, useEffect } from 'react';
import './App.css';
import Hero from './components/Hero.js';
import { useTranslation } from 'react-i18next';
import Aos from 'aos';
import 'aos/dist/aos.css';
import DotLoader from "react-spinners/DotLoader";


function App() {

  // Loading
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

    // Animación
    useEffect(() => {
       Aos.init();
    }, [])

  // Traducción
  const [t, i18n] = useTranslation('global');

  return (
    <div className="App">
      
  { loading ? (
    <DotLoader color={"#254597"} loading={loading} size={50} aria-label="Loading Spinner" data-testid="loader" />
  ) : (
    <div>
      <Hero />
   </div>
    )}

    </div>

  );
}

export default App;